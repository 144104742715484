import React, { useState, useEffect } from "react";
import DrawArea from "./DrawArea";
import { Document, Page, pdfjs } from "react-pdf";
import { Button, Pagination, Paper, Stack } from "@mui/material";

pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/3.0.279/pdf.worker.min.js";

export default function SinglePage({
  resetButtonType,
  buttonType,
  pdf,
  pageChange,
  getPaths,
  flag,
  getBounds,
  changeFlag,
  setIsLoading,
}: {
  resetButtonType: any;
  buttonType: any;
  pdf: string;
  pageChange: any;
  getPaths: any;
  flag: any;
  getBounds: any;
  changeFlag: any;
  setIsLoading: any;
}) {
  const [numPages, setNumPages] = useState<any>(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }: { numPages: any }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  useEffect(() => {
    pageChange(pageNumber);
  });

  function changePage(offset: any) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  function removeTextLayerOffset() {
    const textLayers = document.querySelectorAll(
      ".react-pdf__Page__textContent",
    );
    textLayers.forEach((layer: any) => {
      const { style } = layer;
      style.display = "none";
    });
  }

  return (
    <>
      <div>
        <Paper>
          <Document
            file={pdf}
            // options={{ workerSrc: "/pdf.worker.js" }}
            onSourceError={(err) => console.log(err)}
            onSourceSuccess={() => {
              setIsLoading(false);
            }}
            onLoadSuccess={onDocumentLoadSuccess}
            onLoadError={() => setIsLoading(true)}
          >
            <DrawArea
              getPaths={getPaths}
              page={pageNumber}
              flag={flag}
              getBounds={getBounds}
              changeFlag={changeFlag}
              buttonType={buttonType}
              resetButtonType={resetButtonType}
            >
              <Page
                pageNumber={pageNumber}
                renderAnnotationLayer={false}
                onLoadSuccess={removeTextLayerOffset}
              />
            </DrawArea>
          </Document>
        </Paper>
      </div>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>
        <Button onClick={previousPage} disabled={pageNumber <= 1}>
          Prev
        </Button>
        <Button onClick={nextPage} disabled={pageNumber >= numPages}>
          Next
        </Button>
      </div>
    </>
  );
}
