import React, { useState, useEffect, useCallback, useMemo } from "react";
import {
  Droppable,
  Draggable,
  DragDropContext,
  DropResult,
} from "react-beautiful-dnd";
import {
  Card,
  Stack,
  Divider,
  MenuItem,
  IconButton,
  CardHeader,
  CircularProgress,
  Box,
  Button,
  Modal,
  Typography,
  Chip,
  List,
  CardContent,
  Grid,
  TextField,
  ClickAwayListener,
  Popper,
  Fade,
  Paper,
} from "@mui/material";
import {
  EditOutlined,
  DeleteOutline,
  Add,
  TaskAlt,
  AddCircle,
  AddSharp,
  Title,
  ArrowLeft,
  ArrowRight,
  Edit,
} from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import TaskForm from "./Task";
import { Column, Task } from "../../structs/gen/task";
import { api } from "../../services/api";
import { User, UserListResponse } from "../../structs/gen/user";
import { Document } from "../../structs/gen/document";
import { get, set } from "lodash";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import DeleteWithConfirm from "../DeleteWithConfirm";

const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  // change background colour if dragging
  background: isDragging ? "none" : "none",

  // styles we need to apply on draggables
  ...draggableStyle,
});

const getListStyle = (isDraggingOver: boolean) => ({
  background: isDraggingOver ? "none" : "none",
  overflow: "scroll",
});

async function tasksList(callback: any) {
  let resp = await api.post(`/api/task/list`, {
    offset: 0,
    next: 10,
    filters: [],
  });
  console.log("resp.data", resp.data);
  callback(resp.data);
}

async function taskUpdate(task: Task, callback: any) {
  let resp = await api.post(`/api/task/${task.id}`, task);
  callback(resp.data);
}

async function columnCreate(column: Column, callback: any) {
  let resp = await api.post(`/api/column/`, column);
  callback(resp.data);
}

async function columnUpdate(column: Column, callback: any) {
  let resp = await api.post(`/api/column/${column.id}`, column);
  callback(resp.data);
}

async function columnDelete(column: Column, callback: any) {
  let resp = await api.delete(`/api/column/${column.id}`);
  callback(resp.data);
}

function getNewTaskIndex(column: Column, newIndex: number) {
  var minValue = 0;
  var maxValue = 9223372036854775807;

  if (column.tasks !== undefined && column.tasks.length > 0) {
    var prev = newIndex - 1;
    if (prev >= 0) {
      minValue = column.tasks[prev].index!;
    }

    var next = newIndex;
    if (next < column.tasks.length) {
      maxValue = column.tasks[next].index!;
    }
  }

  return (minValue + maxValue) / 2;
}

function getNewColumnIndex(columns: Column[], newIndex: number) {
  var minValue = 0;
  var maxValue = 9223372036854775807;

  var prev = newIndex - 1;
  if (prev >= 0) {
    minValue = columns[prev].index!;
  }

  var next = newIndex;
  if (next < columns.length) {
    maxValue = columns[next].index!;
  }

  return (minValue + maxValue) / 2;
}

export default function Kanban({ title, subheader, ...other }: any) {
  const { t } = useTranslation();
  const [column, setColumn] = useState<Column | undefined>(undefined);
  const [columns, setColumns] = useState<Column[]>([]);
  const [users, setUsers] = React.useState<User[] | undefined>(undefined);
  const [documents, setDocuments] = React.useState<Document[]>([]);

  const [loading, setLoading] = useState(false);
  const [task, setTask] = useState<Task | undefined>(undefined);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => {
    setOpenModal(false);
  };
  const [isDraggingAnyCard, setIsDraggingAnyCard] = useState(false);

  const onDragEnd = (result: DropResult) => {
    console.log("onDragEnd", onDragEnd, result);
    // dropped outside the list
    if (
      !result.destination ||
      !result.source ||
      !result.source.droppableId ||
      !result.destination.droppableId
    ) {
      return;
    }

    var updatedColumn = columns.find(
      (c) => c.id === result.destination?.droppableId,
    );

    if (!updatedColumn) {
      console.log("Column not found");
      return;
    }

    const task = columns
      .find((c) => c.id === result.source.droppableId)
      ?.tasks.find((t) => t.id === result.draggableId);

    if (!task) {
      console.log("Task not found");
      return;
    }

    task.index = getNewTaskIndex(updatedColumn!, result.destination.index);
    task.column_id = result.destination.droppableId;

    if (!task) {
      console.log("Task not found");
      return;
    }

    var newColumns = columns.map((column) => {
      if (column.id === result.source.droppableId) {
        column.tasks = column.tasks.filter((t) => t.id !== result.draggableId);
      }
      if (result.destination && column.id === result.destination.droppableId) {
        column.tasks.splice(result.destination.index, 0, task);
      }
      return column;
    });

    taskUpdate(task, (resp: any) => {
      console.log("taskUpdate", resp);
    });

    setColumns(newColumns);
    setIsDraggingAnyCard(false);
  };

  const onDragStart = () => {
    setIsDraggingAnyCard(true);
  };

  useEffect(() => {
    setLoading(true);
    apiListUsers().then((resp) => {
      setUsers(resp.rows);
    });
    tasksList((resp: any) => {
      setColumns(resp.columns);
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <>
      {task && (
        <Modal
          open={openModal}
          onClose={handleCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <TaskForm
            task={task}
            setTask={setTask}
            documents={documents}
            users={users}
            onSave={() => {
              setLoading(true);
              handleCloseModal();
              tasksList((resp: any) => {
                setColumns(resp.columns);
                setLoading(false);
              });
            }}
          />
        </Modal>
      )}
      <Card {...other} variant="outlined">
        <CardHeader title={title} subheader={subheader} />
        <Divider />
        <CardContent
          style={{
            width: "100%",
            overflow: "auto",
            whiteSpace: "nowrap",
          }}
        >
          <Grid
            style={{
              width: "max-content",
              overflow: "auto",
              whiteSpace: "nowrap",
            }}
            container
            justifyContent="left"
            spacing={2}
            direction="row"
          >
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              {columns.map((column: Column) => (
                <Droppable droppableId={column.id} key={column.id}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      <List
                        sx={{
                          width: 290,
                          border: 1,
                          borderColor: "divider",
                          margin: 1,
                          borderRadius: 1,
                        }}
                      >
                        <CardHeader
                          title={
                            <Typography variant="h6">{column.name}</Typography>
                          }
                          style={{ textAlign: "center" }}
                          avatar={
                            <IconButton
                              aria-label="add"
                              color="primary"
                              onClick={() => {
                                setTask({
                                  id: "",
                                  title: "",
                                  description: "",
                                  user_id: undefined,
                                  user_name: "",
                                  spent_minutes: 0,
                                  column_id: column.id,
                                  index: getNewTaskIndex(
                                    column,
                                    column.tasks.length,
                                  ),
                                  convertValues: new Task().convertValues,
                                });
                                handleOpenModal();
                              }}
                            >
                              <AddSharp />
                            </IconButton>
                          }
                          action={
                            <PopupState
                              variant="popper"
                              popupId="demo-popup-popper"
                            >
                              {(popupState) => (
                                <div>
                                  <ClickAwayListener
                                    onClickAway={() => {
                                      popupState.close();
                                    }}
                                  >
                                    <div>
                                      <IconButton {...bindToggle(popupState)}>
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Popper
                                        {...bindPopper(popupState)}
                                        transition
                                      >
                                        {({ TransitionProps }) => (
                                          <Fade
                                            {...TransitionProps}
                                            timeout={350}
                                          >
                                            <Paper>
                                              <MenuItem>
                                                <Button
                                                  onClick={() => {}}
                                                  startIcon={<Edit />}
                                                >
                                                  Rename
                                                </Button>
                                              </MenuItem>
                                              {columns.indexOf(column) > 0 && (
                                                <MenuItem>
                                                  <Button
                                                    onClick={() => {
                                                      var indexInArray =
                                                        columns.indexOf(column);

                                                      var newIndex =
                                                        getNewColumnIndex(
                                                          columns.filter(
                                                            (c) =>
                                                              c.id !==
                                                              column.id,
                                                          ),
                                                          indexInArray - 1,
                                                        );

                                                      column.index = newIndex;

                                                      columnUpdate(
                                                        column,
                                                        (resp: any) => {
                                                          setLoading(true);
                                                          tasksList(
                                                            (resp: any) => {
                                                              setColumns(
                                                                resp.columns,
                                                              );
                                                              setLoading(false);
                                                            },
                                                          );
                                                        },
                                                      );
                                                    }}
                                                    startIcon={<ArrowLeft />}
                                                    color={"secondary"}
                                                  >
                                                    Left
                                                  </Button>
                                                </MenuItem>
                                              )}
                                              {columns.indexOf(column) <
                                                columns.length - 1 && (
                                                <MenuItem>
                                                  <Button
                                                    onClick={() => {
                                                      var indexInArray =
                                                        columns.indexOf(column);

                                                      var newIndex =
                                                        getNewColumnIndex(
                                                          columns.filter(
                                                            (c) =>
                                                              c.id !==
                                                              column.id,
                                                          ),
                                                          indexInArray + 1,
                                                        );

                                                      column.index = newIndex;

                                                      columnUpdate(
                                                        column,
                                                        (resp: any) => {
                                                          setLoading(true);
                                                          tasksList(
                                                            (resp: any) => {
                                                              setColumns(
                                                                resp.columns,
                                                              );
                                                              setLoading(false);
                                                            },
                                                          );
                                                        },
                                                      );
                                                    }}
                                                    endIcon={<ArrowRight />}
                                                    color={"secondary"}
                                                  >
                                                    Right
                                                  </Button>
                                                </MenuItem>
                                              )}
                                              <MenuItem>
                                                <DeleteWithConfirm
                                                  onClick={() => {
                                                    columnDelete(
                                                      column,
                                                      (resp: any) => {
                                                        setLoading(true);
                                                        tasksList(
                                                          (resp: any) => {
                                                            setColumns(
                                                              resp.columns,
                                                            );
                                                            setLoading(false);
                                                          },
                                                        );
                                                      },
                                                    );
                                                  }}
                                                  text={"Delete"}
                                                />
                                              </MenuItem>
                                            </Paper>
                                          </Fade>
                                        )}
                                      </Popper>
                                    </div>
                                  </ClickAwayListener>
                                </div>
                              )}
                            </PopupState>
                          }
                        />
                        {isDraggingAnyCard &&
                          column.tasks.length === 0 && ( //show placeholder when dragging
                            <Card
                              {...other}
                              sx={{ width: 270, height: 100, margin: 1 }}
                            ></Card>
                          )}
                        {column.tasks.map((task: Task, index: number) => (
                          <TaskItem
                            index={index}
                            task={task}
                            key={task.id}
                            handleOpenModal={() => {
                              setTask(task);
                              handleOpenModal();
                            }}
                            setLoading={setLoading}
                            setTask={setTask}
                          />
                        ))}
                        {isDraggingAnyCard &&
                          column.tasks.length > 0 && ( //show placeholder when dragging
                            <Box
                              {...other}
                              sx={{ width: 270, height: 100 }}
                            ></Box>
                          )}
                      </List>
                    </div>
                  )}
                </Droppable>
              ))}
            </DragDropContext>
            <CardHeader
              style={{
                display: "inline-block",
              }}
              title={
                <TextField
                  label="Name"
                  value={column?.name}
                  type={"text"}
                  onChange={(e) => {
                    setColumn({
                      ...column,
                      name: e.target.value,
                      id: "",
                      tasks: [],
                      index: getNewColumnIndex(columns, columns.length),
                      convertValues: new Column().convertValues,
                    });
                  }}
                />
              }
              action={
                <IconButton
                  aria-label="add"
                  color="primary"
                  onClick={() => {
                    if (!column) {
                      return;
                    }
                    columnCreate(column, (resp: any) => {
                      setLoading(true);

                      tasksList((resp: any) => {
                        setColumns(resp.columns);
                        setColumn({
                          name: "",
                          id: "",
                          tasks: [],
                          index: 0,
                          convertValues: new Column().convertValues,
                        });
                        setLoading(false);
                      });
                    });
                  }}
                >
                  <AddCircle />
                </IconButton>
              }
            />
          </Grid>
        </CardContent>
      </Card>
    </>
  );
}

TaskItem.propTypes = {
  task: PropTypes.object,
};

function TaskItem({
  index,
  task,
  setTask,
  handleOpenModal,
  setLoading,
  ...other
}: {
  index: number;
  task: Task;
  setTask: any;
  handleOpenModal: any;
  setLoading: any;
}) {
  const { t } = useTranslation();

  return (
    <Draggable key={task.id} draggableId={task.id} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          style={getItemStyle(
            snapshot.isDragging,
            provided.draggableProps.style,
          )}
        >
          <Card
            {...other}
            sx={{ width: 270, height: 100, margin: 1 }}
            onClick={() => {
              setTask(task);
              handleOpenModal();
            }}
          >
            <CardHeader
              title={task.title}
              subheader={task.description}
              // action={
              //   <IconButton
              //     aria-label="settings"
              //     onClick={handleOpenMenu}
              //     size="small"
              //   >
              //     <EditOutlined />
              //   </IconButton>
              // }
            />
            {/* <CardContent>
        <Typography variant="body2" color="text.secondary">
          {task.user_name}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {task.spent_minutes}
        </Typography>
      </CardContent>
      <Divider />
      <Box sx={{ p: 2, textAlign: "right" }}>
        <Button
          size="small"
          color="primary"
          endIcon={<MoreVert />}
          onClick={() => {
            setIsShowingMore(!isShowingMore);
          }}
        >
          {isShowingMore ? "Less" : "More"}
        </Button>
      </Box> */}
          </Card>
        </div>
      )}
    </Draggable>
  );
}

async function apiListUsers(): Promise<UserListResponse> {
  let resp = await api.post(`/api/user/list?current=yes`, {
    offset: 0,
    next: 100,
    filters: [],
  });
  return resp.data;
}
