/* Do not change, this code is generated from Golang structs */


export class String {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Time {


    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);

    }
}
export class Task {
    id: string;
    title: string;
    description: string;
    spent_minutes?: number;
    deadline_datetime?: Time;
    user_name?: string;
    user_id?: String;
    document_ids?: string[];
    column_id?: string;
    index: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.title = source["title"];
        this.description = source["description"];
        this.spent_minutes = source["spent_minutes"];
        this.deadline_datetime = this.convertValues(source["deadline_datetime"], Time);
        this.user_name = source["user_name"];
        this.user_id = this.convertValues(source["user_id"], String);
        this.document_ids = source["document_ids"];
        this.column_id = source["column_id"];
        this.index = source["index"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class Column {
    id: string;
    name: string;
    tasks: Task[];
    index: number;

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.id = source["id"];
        this.name = source["name"];
        this.tasks = this.convertValues(source["tasks"], Task);
        this.index = source["index"];
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
export class TaskListResponse {
    count: number;
    columns: Column[];

    constructor(source: any = {}) {
        if ('string' === typeof source) source = JSON.parse(source);
        this.count = source["count"];
        this.columns = this.convertValues(source["columns"], Column);
    }

	convertValues(a: any, classs: any, asMap: boolean = false): any {
	    if (!a) {
	        return a;
	    }
	    if (a.slice) {
	        return (a as any[]).map(elem => this.convertValues(elem, classs));
	    } else if ("object" === typeof a) {
	        if (asMap) {
	            for (const key of Object.keys(a)) {
	                a[key] = new classs(a[key]);
	            }
	            return a;
	        }
	        return new classs(a);
	    }
	    return a;
	}
}
