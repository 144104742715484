import axios from "axios";
import { reject } from "lodash";
import { useNavigate } from "react-router-dom";
import { LOGIN_URL } from "../structs/urls";
import Cookies from "js-cookie";

const TOKEN = "token";
const CookieKey = "elena";

export let getAuthToken = () => {
  var storageToken = localStorage.getItem(TOKEN);
  if (storageToken && storageToken !== "") {
    return storageToken;
  } else {
    var cookieElena = Cookies.get(CookieKey);
    if (cookieElena && cookieElena !== "") {
      localStorage.setItem(TOKEN, cookieElena);
    }
    return cookieElena;
  }
};

export let api = axios.create({
  baseURL:
    process.env.REACT_APP_SERVER_URI &&
    (process.env.REACT_APP_SERVER_URI as string) !== ""
      ? (process.env.REACT_APP_SERVER_URI as string)
      : "https://app.elena.sh",
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

export let axios_external = axios.create({
  timeout: 10000,
  headers: {
    Authorization: `Bearer ${getAuthToken()}`,
  },
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    //hashHistory.push(`menu/errorPage/${error.response.status}`);
    // message.error(`Error ${error.response.status}`);
    if (+error.response.status >= 400 && +error.response.status < 500) {
      localStorage.removeItem(TOKEN);
      Cookies.remove(CookieKey);
      //hashHistory.push('/');
      const navigate = useNavigate();
      navigate(LOGIN_URL);
    } else {
      alert(error.response.data.error);
    }
    reject?.(error);
  },
);

export const setToken = (token: string | undefined) => {
  if (token) {
    localStorage.setItem(TOKEN, token);
  } else {
    localStorage.removeItem(TOKEN);
  }
  api = axios.create({
    baseURL:
      process.env.REACT_APP_SERVER_URI &&
      (process.env.REACT_APP_SERVER_URI as string) !== ""
        ? (process.env.REACT_APP_SERVER_URI as string)
        : "https://app.elena.sh",
    timeout: 10000,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export let isLogin = (): boolean => {
  var token = getAuthToken();
  if (token && token !== null) {
    return true;
  }
  return false;
};
